import React from "react";

import LOGO from "../../Assets/img/logo/maubeuge.png";
import { useStyles } from "./style";

const Logo: React.FC = () => {
  const style = useStyles();
  return (
    <div>
      <img src={LOGO} alt="logo" className={style.img} />
      <h4>PHARMACIE DE MAUBEUGE</h4>
    </div>
  );
};

export default Logo;
